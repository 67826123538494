import ContactPipeline from "./ContactPipeline";
import ContactMilestone from "./ContactMilestone";
import { useContacts } from "../../../app/hooks/useContacts";
import AjaxLoader from "../../../app/common/common-components/AjaxLoader";
import ContactFilter from "./ContactFilter";
import { OMSDiaryNoteItem } from "../../../app/common/common-components/OMSDiaryNoteComponents/OMSDiaryNoteItem";
import { useRef, useState } from "react";
import { useStore } from "../../../app/stores/store";
import { toast } from "react-toastify";
import ContactGrid from "../ContactGrid";
import { LEAD_ACTION_TYPE, LEAD_TYPE, VIEW, VIEW_TYPE } from "../constants";
import ContactLeadGrid from "../ContactLeadGrid";

const ContactBoard = () => {
    const {
        contactInfo = {},
        handleChangeLeads = () => { },
        handleChangeSeachText = () => { },
        handleClickLead = () => { },
        handleClickSendEmail = () => { },
        handleContactViewTypeChange = () => { },
        handleDragEnd = () => { },
        handleOnClickMenu = () => { },
        handleTagsChange = () => { },
        handleViewTypeChange = () => { },
        handleWhatsappClick = () => { },
        isLoading = true,
        loadDiaryAjaxForContact = () => { },
        milestoneMaxHeight = 0,
        tagColorFormatter = () => { },
        view = VIEW.DEFAULT,
        handleChangeLeadType = () => { },
        handleChangeLeadActionType = () => { },

    } = useContacts();

    const contactType = contactInfo.selectedContactView;
    const viewType = contactInfo.selectedViewType;


    const noteItemRef = useRef();
    const [currentContactId, setCurrentContactId] = useState("");
    const {
        userStore: { userSession, logout, logoutAs, isLoggedIn },
    } = useStore();
    const userId = userSession?.userId;

    const {
        officeTags = [],
        sourceTypesTag = [],
        userTags = [],
        usersDD = [],
    } = contactInfo;

    const {
        actions = [],
        diaryActionTags = [],
        pipelines = [],
        selectedPipelineId,
        stages = [],
        searchTerm = "",
        mLeads = 0,
        mLeadActionType = LEAD_ACTION_TYPE.NEW,
        mLeadType = LEAD_TYPE.ALL,
    } = contactInfo;

    const handleClickNewActivity = (contactId) => {
        setCurrentContactId(contactId);
        noteItemRef.current.onOpenDairyNoteItem(null);
    }
    const handleDiaryItemSubmitCompleteFunc = () => {
        toast.info("Diary saved successfully.");
        loadDiaryAjaxForContact();
    }



    // Conditional rendering based on loading state
    if (isLoading) {
        return <AjaxLoader />
    }
    return (
        <>

            <div className="contact-main-component">

                {
                    <div className={"col-md-2"}>
                        <div className="menu-item contact-section">
                            <ContactPipeline
                                onClickMenu={handleOnClickMenu}
                                pipelines={pipelines}
                                selectedPipeline={selectedPipelineId}
                                onLeadClick={handleClickLead}
                                view={view}
                            />
                        </div>
                    </div>
                }
                <div className={"col-md-10"}>
                    <div className="contact-wrapper-section contact-section">
                        <div className="filter-section">
                            {
                                <ContactFilter
                                    actions={actions}
                                    contactType={contactType}
                                    officeTags={officeTags}
                                    onChangeContactViewType={handleContactViewTypeChange}
                                    onChangeTags={handleTagsChange}
                                    onChangeViewType={handleViewTypeChange}
                                    onChangeSeachText={handleChangeSeachText}
                                    sourceTypesTag={sourceTypesTag}
                                    userTags={userTags}
                                    viewType={viewType}
                                    searchTerm={searchTerm}
                                    view={view}
                                    usersDD={usersDD}
                                    onChangeLeads={handleChangeLeads}
                                    mLeads={mLeads}
                                    mLeadActionType={mLeadActionType}
                                    mLeadType={mLeadType}
                                    onChangeLeadType={handleChangeLeadType}
                                    onChangeLeadActionType={handleChangeLeadActionType}
                                />
                            }
                        </div>
                        {stages && stages.length > 0 && view !== VIEW.LEAD && (

                            viewType != VIEW_TYPE.LIST &&
                            <div className="contact-draggable-area">

                                <ContactMilestone
                                    actions={actions}
                                    milestoneMaxHeight={milestoneMaxHeight}
                                    officeTags={officeTags}
                                    onClickSendEmail={handleClickSendEmail}
                                    onClickNewActivity={handleClickNewActivity}
                                    onDragEnd={handleDragEnd}
                                    onWhatsappClick={handleWhatsappClick}
                                    stages={stages}
                                    tagColorFormatter={tagColorFormatter}
                                    userTags={userTags}
                                    viewType={viewType}

                                />

                                <OMSDiaryNoteItem
                                    DiaryActionTags={diaryActionTags}
                                    DNType={1}
                                    htmlID="noteItem-contact"
                                    ID={currentContactId}
                                    IsReadOnly={(actions.indexOf('create') < 0)}
                                    OnShowEnd={userSession?.headerValues?.onShowEnd}
                                    OnShowStart={userSession?.headerValues?.onShowStart}
                                    onDiaryItemSubmitCompleteFunc={handleDiaryItemSubmitCompleteFunc}
                                    ref={noteItemRef}
                                    UserID={userId}
                                />
                            </div>)
                        }


                        {
                            viewType !== VIEW_TYPE.LIST && stages.length === 0 && view !== VIEW.LEAD && (
                                <div class="message-container">
                                    <h4>There are no milestones in the pipeline. Please configure the pipeline with milestones to ensure it works correctly.</h4>
                                </div>
                            )
                        }
                        {viewType === VIEW_TYPE.LIST && selectedPipelineId !== "" && view !== VIEW.LEAD && (
                            <ContactGrid
                                contact={contactInfo}
                                pipelineID={selectedPipelineId}
                                searchTerm={searchTerm}
                                viewType={viewType}
                                sources={contactInfo.selectedSources}
                                tags={contactInfo.selectedTags}
                                contactViewType={contactInfo.selectedContactView}

                            />
                        )}

                        {
                            view === VIEW.LEAD && (
                                <ContactLeadGrid
                                    contact={contactInfo}
                                    pipelineID={selectedPipelineId}
                                    searchTerm={searchTerm}
                                    view={view}
                                    mLeads={mLeads}
                                    mLeadType={mLeadType}
                                    mLeadActionType={mLeadActionType}
                                />
                            )
                        }


                    </div>
                </div>
            </div>
        </>
    );
}

export default ContactBoard;