export const VIEW_TYPE = {
    LIST: 0,
    CARD_SUMMARY: 1,
    CARD_FULL: 2,
};

export const CONTACT_VIEW_TYPE = {
    MY_CONTACTS: 0,
    ALL_CONTACTS: 1,
};

export const LABEL = {
    MY_CONTACTS: "My Contacts",
    ALL_CONTACTS: "All Contacts",
    LIST: "List",
    CARD_SUMMARY: "Card Summary",
    CARD_FULL: "Card Full",
    RESET_BTN_TEXT: "Reset",
    IS_NOT_DGRAG: "IS_NOT_DGRAG",
    IS_DRAG: "IS_DRAG",
    INBOX_UN_READ: "Inbox (Unread)",
    INBOX_READ: "Inbox (Read)",
    INBOX_ALL: "Inbox (All)",

};
export const CONTACT_CLASS_NAME = {
    ACTIVE_DROP_DOWN: "filter-dropdown-active",
    LAST_MONTH_EVENT: "card-before-event",
    NEXT_24_HOURS_EVENT: "card-after-event",
}
export const VIEW = {

    DEFAULT: 0,
    LEAD: 4
}
export const LEAD_TYPE = {
    UN_READ: 0,
    READ: 1,
    ALL: 2
}
export const LEAD_ACTION_TYPE = {
    NEW: 0,
    CREATE: 1
}
