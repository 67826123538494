import React from "react";
import { OMSMultiselectTagDropdown2 } from "../../app/common/common-components/OMSMultiselectTagDropdown";
import { OMSDropdown } from "../../app/common/common-components/OMSDropdown";
import { OMSButtonStyleL } from "../../app/common/common-components/OMSButton";
import { DataTableComp, DataTableCompDynamic } from "../../app/common/common-components/OMSDatatables";
import { OMSLeadActionModel } from "../../app/common/common-components/OMSInboxComponents/OMSLeadActionModel";
import { ReportGrid } from "./ReportGrid";
import apiAgent from "../../app/api/apiAgent";
import { RefFindNode } from "@fluentui/react-component-ref";
import { DataTableLoading } from "../../app/common/common-components/OMSDatatables";
import { withRouter } from 'react-router-dom';
import { VIEW_TYPE } from "./constants";

class ContactGrid extends React.Component {

  debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  };
  constructor(props) {
    super(props);
    this.reportGridRef = React.createRef();
    this.leadBoxRef = React.createRef();
  }

  loadContactsFromServer = (
    view,
    tags,
    sources,
    contactViewType,
    searchTerm,
    pipelineId) => {
    this.setState({
      view: view,
      tags: tags,
      sources: sources,
      mContactViewType: contactViewType,
      mPipelineId: pipelineId,
      mSearch: searchTerm,
      ExtraParams: JSON.stringify({
        Tags: tags.join(),
        Sources: sources.join(),
        ContactViewType: contactViewType,
        Search: searchTerm,
        PipelineId: pipelineId
      }),
    });
  };

  loadGrid = (requestParams) => {
    const { searchTerm = "", tags = [], sources = [], pipelineID = "", contactViewType = 0 } = this.props;
    const contactsData = apiAgent.Contact.GetContactsDataForListGrid(searchTerm, tags, sources, contactViewType, pipelineID);
    return contactsData;
  };

  componentDidMount() {
    // this.loadContactsFromServer(
    //   1,
    //   this.state.tags,
    //   this.state.sources,
    //   this.state.mContactViewType,
    //   ""
    // );
  }
  componentDidUpdate(prevProps, prevState) {


    const { searchTerm = "", pipelineID = "", contactViewType = 0, tags = [], sources = [] } = this.props;

    if (
      prevProps.searchTerm !== searchTerm ||
      prevProps.pipelineID !== pipelineID ||
      prevProps.contactViewType !== contactViewType
    ) {
      this.debouncedLoadContacts();
    }
    else {
      this.debouncedLoadContacts();

    }

  }

  arraysAreDifferent = (arr1, arr2) => {

    if (arr1 === arr2) return false; // Same reference means no difference
    if (arr1?.length !== arr2?.length) return true; // Different lengths

    // Check if any item is missing or different
    return arr1?.some((item, index) => item !== arr2[index]) ||
      arr2?.some((item, index) => item !== arr1[index]);
  };

  RefreshLeadItems = () => {
    this.loadLeadsFromServer(this.state.view, this.state.mLeads, this.state.mLeadType, this.state.mLeadActionType);
  };

  handleRowClick = (data) => {
    this.props.history.push('/Contacts/SaveContact?ID=' + data.contactID);
  };

  debouncedLoadContacts = this.debounce(() => {
    const { searchTerm = "", pipelineID = "", contactViewType = 0, tags = [], sources = [] } = this.props;
    this.loadContactsFromServer(1, tags, sources, contactViewType, searchTerm, pipelineID);
  }, 300);


  state = {
    loading: false,
    data2: [],
    view: 1,
    pageView: 1,
    tags: [],
    sources: [],
    mLeads: 0,
    mLeadType: 2,
    mLeadActionType: 0,
    mContactViewType: 0,
    mPipelineId: "",
    mSearch: "",
    ExtraParams: JSON.stringify({ View: 1, Tags: "", Sources: "", ContactViewType: 0, Search: "" }),

  };

  tag_format = (value) => {
    var officeContactTags = this.props.contact.officeContactTags;
    var userContactTags = this.props.contact.userContactTags;

    if (value == undefined || value == "" || officeContactTags == undefined || userContactTags == undefined) return "";

    var columnHtml = "";
    var splitValue = value.split(",");
    for (var i = 0; i < splitValue.length; i++) {
      var btnHtml = "";
      for (var j = 0; j < officeContactTags.length; j++) {
        if (officeContactTags[j].name == splitValue[i]) {
          btnHtml = '<span class="btn btn-tag-grid-label" style="background-color:' + officeContactTags[j].color + ';">' + splitValue[i] + "</span>";
          j = officeContactTags.length;
        }
      }

      if (btnHtml == "") {
        for (var k = 0; k < userContactTags.length; k++) {
          if (userContactTags[k].name == splitValue[i]) {
            btnHtml = '<span class="btn btn-my-tag-grid-label" style="background-color:' + userContactTags[k].color + ';">' + splitValue[i] + "</span>";
            k = userContactTags.length;
          }
        }
      }

      if (btnHtml != "") {
        columnHtml = columnHtml + btnHtml;
      }
    }
    return columnHtml;
  };

  source_format = (value) => {
    var contactSourceTypes = this.props.contact.contactSourceTypes;

    if (value == undefined || value == "" || contactSourceTypes == undefined) return "";

    for (var j = 0; j < contactSourceTypes.length; j++) {
      if (contactSourceTypes[j].name == value) {
        return '<span class="btn btn-tag-grid-label" style="background-color:' + contactSourceTypes[j].color + ';">' + value + "</span>";
      }
    }
    return "";
  };

  render() {
    const columns = [
      { data: "name", title: "Name" },
      { data: "companyName", title: "Company" },
      { data: "phoneNumber", title: "Number" },
      { data: "emailAddress", title: "Email address" },
      { data: "source", title: "Source" },
      { data: "stageName", title: "Milestone" },
      { data: "tagsString", title: "Tags", formatter: "tag_format" },
      { data: "lastActivity", title: "Activity" },
    ];

    const columnDefs = [
      {
        render: function (data, type, row) {
          return this.source_format(data);
        }.bind(this),
        targets: 4,
      },
      {
        render: function (data, type, row) {
          return this.tag_format(data);
        }.bind(this),
        targets: 6,
      },
    ];


    return (
      <div className="row">

        <div className="col-md-12 col-sm-12" style={{ display: this.state.pageView == 1 ? "" : "none" }}>
          <div className="panel panel-default panel-table oms-box" data-intercom-target="grid-panel">

            <div className="panel-body">
              <div className="table-responsive">
                <DataTableCompDynamic
                  id="contactTable"
                  columns={columns}
                  columnDefs={columnDefs}
                  order={[[0, "asc"]]}
                  serverSide={false}
                  makeAjaxRequest={this.loadGrid}
                  onRowClick={this.handleRowClick}
                  ExtraParams={this.state.ExtraParams}
                  searching={false}
                />

                <DataTableLoading loading={this.state.loading} />
              </div>

              <div id="lead_container">
                <OMSLeadActionModel
                  IsReadOnly={(this.props.contact.actions.indexOf("create") < 0)}
                  OfficeContactTags={this.props.contact.officeContactTags}
                  UserContactTags={this.props.contact.userContactTags}
                  ContactSourceTypes={this.props.contact.contactSourceTypes}
                  DialingCode={this.props.contact.dialingCode}
                  DialingCodeDD={this.props.contact.dialingCodeDD}
                  ref={this.leadBoxRef}
                  ID="myLeadBox"
                  UserID=""
                  View={1}
                  RefreshFun={this.RefreshLeadItems}
                />
              </div>
            </div>
          </div>
        </div>
        {this.props.contact.super_admin ? <div className="col-md-11 col-sm-12" style={{ display: this.state.pageView == 100 ? "" : "none" }}></div> : null}
      </div>
    );
  }
}

export default withRouter(ContactGrid);
