import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { webref_format, ToPriceString, ref_format, deal_webref_format, task_roles_format, ToTaskStageString } from "../common-functions/functions";
import "font-awesome/css/font-awesome.min.css";
import { withRouter } from "react-router-dom";
//import $ from 'jquery';
//window.datatables = require('datatables.net-bs');
//const $ = require('jquery');
//$.DataTable = require('datatables.net-bs');
const $ = require("jquery");
$.DataTable = require("datatables.net");

export class DataTableComp extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    columnDefs: PropTypes.array,
    onRowClick: PropTypes.func,
    hasTableShown: PropTypes.bool,
    order: PropTypes.array,
    pageSize: PropTypes.number,
    showSearchBox: PropTypes.bool,
  };

  static defaultProps = {
    id: "gridtable",
    columns: [],
    data: [],
    hasTableShown: false,
    pageSize: 10,
    showSearchBox: true,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.myTable = $("#" + this.props.id).DataTable({
      responsive: true,
      processing: true,
      language: {
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span>',
      },
      serverSide: false,
      ordering: true,
      searching: this.props.showSearchBox,
      paging: true,
      pageLength: this.props.pageSize,
      data: this.props.data,
      columns: this.props.columns,
      columnDefs: this.props.columnDefs,
      order: this.props.order,
    });

    if (this.props.onRowClick !== undefined) {
      $("#" + this.props.id + " tbody").on(
        "click",
        "tr",
        function (e) {
          if (e.offsetX > 35) {
            var data = this.myTable.row(e.target).data();
            if (data !== undefined && this.props.onRowClick !== undefined) this.props.onRowClick(data);
          }
        }.bind(this)
      );
    }
  }

  componentWillUnmount() {
    if (this.myTable !== undefined) this.myTable.destroy(true);
  }

  reloadTableData = (data) => {
    this.myTable.clear().rows.add(data).draw();
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.data.length !== this.props.data.length) {
      this.reloadTableData(nextProps.data);
    }

    if (nextProps.hasTableShown && !this.props.hasTableShown) {
      $("#" + this.props.id).css("width", "100%");
    }

    return false;
  }

  render() {
    return <table id={this.props.id} className="table table-hover" width="100%"></table>;
  }
}

export function DataTableLoading(props) {
  if (props.loading === true) {
    return (
      <div className="loading text-center">
        <div className="loading-bar"></div>
        <div className="loading-bar"></div>
        <div className="loading-bar"></div>
        <div className="loading-bar"></div>
      </div>
    );
  } else return null;
}

export class DataTableCompDynamic extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    columns: PropTypes.array.isRequired,
    Url: PropTypes.string.isRequired,
    columnDefs: PropTypes.array,
    onRowClick: PropTypes.func,
    ExtraParams: PropTypes.string,
    serverSide: PropTypes.bool,
    ordering: PropTypes.bool,
    order: PropTypes.array,
    hasTableShown: PropTypes.bool,
    makeAjaxRequest: PropTypes.func,
  };
  static defaultProps = {
    id: "gridtable",
    columns: [],
    Url: "",
    serverSide: true,
    ordering: true,
    order: [],
    hasTableShown: false,
    searching: true
  };
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.ExtraParams = this.props.ExtraParams;
    this.myTable = $("#" + this.props.id).DataTable({
      ajax: (requestParams, callback) => {
        //console.log("inside ajax, requestParams=", requestParams);
        this.props
          .makeAjaxRequest(requestParams)
          .then((response) => {
            //console.log("inside dynamic grid after response",response);
            callback({
              data: response.data,
              recordsTotal: response.recordsTotal,
              recordsFiltered: response.recordsFiltered,
            });
          })
          .catch((error) => {
            //console.error(error);
          });
      },
      // ajax: {
      //   url: "" + this.props.Url,
      //   type: "POST",
      //   data: function (d) {
      //     if (this.ExtraParams !== undefined && this.ExtraParams !== null && this.ExtraParams != "") {
      //       var extraParams = JSON.parse(this.ExtraParams);
      //       for (var item in extraParams) d[item] = extraParams[item];
      //     }
      //   }.bind(this),
      // },
      responsive: true,
      processing: true,
      language: {
        infoFiltered: "",
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> ',
      },
      serverSide: this.props.serverSide,
      //serverSide: true,
      ordering: this.props.ordering,
      searching: this.props.searching,
      paging: true,
      columns: this.props.columns,
      columnDefs: this.props.columnDefs,
      order: this.props.order,
    });

    if (this.props.onRowClick !== undefined) {
      $("#" + this.props.id + " tbody").on(
        "click",
        "tr",
        function (e) {
          //if (e.offsetX > 35)  this condition was here for mobile view related stuff.....
          var data = this.myTable.row(e.target).data();
          if (data !== undefined && this.props.onRowClick !== undefined) this.props.onRowClick(data);
        }.bind(this)
      );
    }
  }
  componentWillUnmount() {
    if (this.myTable !== undefined) this.myTable.destroy(true);
  }
  reloadTableData = () => {
    this.myTable.ajax.reload();
  };
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.ExtraParams != this.props.ExtraParams) {
      this.ExtraParams = nextProps.ExtraParams;
      this.reloadTableData();
    }

    if (nextProps.hasTableShown !== undefined && this.props.hasTableShown !== undefined) {
      if (nextProps.hasTableShown && !this.props.hasTableShown) {
        $("#" + this.props.id).css("width", "100%");
      }
    }

    return false;
  }

  render() {
    return <table id={this.props.id} className="table table-hover"></table>;
  }
}

class DataTableCompDynamicP extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onRowClick: PropTypes.func,
    ExtraParams: PropTypes.string,
    makeAjaxRequest: PropTypes.func,
  };
  static defaultProps = {
    id: "gridtable",
  };
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.ExtraParams = this.props.ExtraParams;
    //console.log("inside dynamic grid P, componentDidMount, Props:", this.props);
    this.initDataTable(JSON.parse(this.props.ExtraParams).View, this.props.HColumns);
  }

  initDataTable = (View, HColumns) => {
    if (View == 2) {
      this.initDataTable2(HColumns);
    } else {
      this.initDataTable1();
    }

    $(".dataTables_filter input")
      .unbind()
      .bind(
        "input",
        function (e) {
          if (this.delayTimer !== undefined) clearTimeout(this.delayTimer);

          this.delayTimer = setTimeout(
            function () {
              if (this.state.search != this.state.search_current) {
                this.setState({ search_current: this.state.search });
                this.myTable.search(this.state.search).draw();
              }
            }.bind(this),
            1000
          );
          this.setState({ search: e.currentTarget.value });
          return;
        }.bind(this)
      );

    if (this.props.onRowClick !== undefined) {
      $("#" + this.props.id + " tbody").on(
        "click",
        "tr",
        function (e) {
          if (e.offsetX > 35) {
            var data = this.myTable.row(e.target).data();
            if (data !== undefined && this.props.onRowClick !== undefined) this.props.onRowClick(data);
          }
        }.bind(this)
      );
    }
  };

  initDataTable1 = () => {
    var columns = [
      // { data: "SyncStatusWebRef", title: "WebRef" },
      // { data: "CityOrTown", title: "Town" },
      // { data: "DistrictOrSuburb", title: "Suburb" },
      // { data: "Street", title: "Street", orderable: false },
      // { data: "Complex", title: "Complex", orderable: false },
      // { data: "Price", title: "Price" },
      // { data: "PropertyType", title: "PropertyType" },
      // { data: "SaleType", title: "SaleType" },
      // { data: "Status", title: "Status" },
      // { data: "Mandate", title: "Mandate" },
      // { data: "Days", title: "Days", orderable: false },
      // { data: "AgentNames", title: "Agents", orderable: false },
      { data: "syncStatusWebRef", title: "WebRef" },
      { data: "cityOrTown", title: "Town" },
      { data: "districtOrSuburb", title: "Suburb" },
      { data: "street", title: "Street", orderable: false },
      { data: "complex", title: "Complex", orderable: false },
      { data: "price", title: "Price" },
      { data: "propertyType", title: "PropertyType" },
      { data: "saleType", title: "SaleType" },
      { data: "status", title: "Status" },
      { data: "mandate", title: "Mandate" },
      { data: "days", title: "Days", orderable: false },
      { data: "agentNames", title: "Agents", orderable: false },
    ];

    var columnDefs = [
      {
        render: function (data, type, row) {
          return webref_format(data, row.propertyID);
        },
        targets: 0,
      },
      {
        render: function (data, type, row) {
          return ToPriceString(data);
        },
        targets: 5,
      },
    ];

    this.myTable = $("#" + this.props.id).DataTable({
      /* ajax: {
        url: "/Property/IndexDynamicAjax",
        type: "POST",
        data: function (d) {
          if (this.ExtraParams !== undefined && this.ExtraParams !== null && this.ExtraParams != "") {
            var extraParams = JSON.parse(this.ExtraParams);
            for (var item in extraParams) d[item] = extraParams[item];
          }
        }.bind(this),
      }, */
      ajax: (requestParams, callback) => {
        this.props
          .makeAjaxRequest(requestParams)
          .then((response) => {
            //console.log(response);
            callback({
              data: response.data,
              recordsTotal: response.recordsTotal,
              recordsFiltered: response.recordsFiltered,
            });
          })
          .catch((error) => {
            //console.error(error);
          });
      },
      responsive: true,
      processing: true,
      language: {
        infoFiltered: "",
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> ',
      },
      serverSide: true,
      ordering: true,
      order: [[0, "desc"]],
      searching: true,
      paging: true,
      columns: columns,
      columnDefs: columnDefs,
      createdRow: function (row, data, dataIndex) {
        $("td", row).on("click", function () {
          //window.location.href = "/Property/SaveProperty?ID=" + data.propertyID;
          //this.props.history.push("/Property/SaveProperty?ID=" + data.propertyID);
        });
      },
    });
  };

  initDataTable2 = (mHColumns) => {
    var columns = [
      // { data: "SyncStatusWebRef", title: "WebRef" },
      // { data: "CityOrTown", title: "Town" },
      // { data: "DistrictOrSuburb", title: "Suburb" },
      // { data: "Price", title: "Price" },
      // { data: "PropertyType", title: "PropertyType" },
      // { data: "SaleType", title: "SaleType" },
      // { data: "Status", title: "Status" },
      // { data: "Mandate", title: "Mandate" },
      { data: "syncStatusWebRef", title: "WebRef" },
      { data: "cityOrTown", title: "Town" },
      { data: "districtOrSuburb", title: "Suburb" },
      { data: "price", title: "Price" },
      { data: "propertyType", title: "PropertyType" },
      { data: "saleType", title: "SaleType" },
      { data: "status", title: "Status" },
      { data: "mandate", title: "Mandate" },
    ];

    //console.log("HCoumns - initDataTable2 - " + mHColumns);
    var HColumns = JSON.parse(mHColumns);
    for (var i = 0; i < HColumns.length; i++) {
      columns.push({
        data: HColumns[i].Value,
        title: HColumns[i].Key,
        width: "50px",
      });
    }

    columns.push({ data: "days", title: "Days" });

    var columnDefs = [
      {
        render: function (data, type, row) {
          return webref_format(data, row.propertyID);
        },
        targets: 0,
      },
      {
        render: function (data, type, row) {
          return ToPriceString(data);
        },
        targets: 3,
      },
    ];

    for (var i = 0; i < HColumns.length; i++) {
      columnDefs.push({
        className: "dt-head-center rotate",
        render: function (data, type, row) {
          return ref_format(data);
        },
        targets: 8 + i,
        orderable: false,
      });
    }

    this.myTable = $("#" + this.props.id).DataTable({
      /* ajax: {
        url: "/Property/IndexDynamicAjax",
        type: "POST",
        data: function (d) {
          if (this.ExtraParams !== undefined && this.ExtraParams !== null && this.ExtraParams != "") {
            var extraParams = JSON.parse(this.ExtraParams);
            for (var item in extraParams) d[item] = extraParams[item];
          }
        }.bind(this),
      }, */
      ajax: (requestParams, callback) => {
        this.props
          .makeAjaxRequest(requestParams)
          .then((response) => {
            callback({
              data: response.data,
              recordsTotal: response.recordsTotal,
              recordsFiltered: response.recordsFiltered,
            });
          })
          .catch((error) => {
            //console.error(error);
          });
      },
      responsive: true,
      processing: true,
      language: {
        infoFiltered: "",
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> ',
      },
      serverSide: true,
      ordering: true,
      order: [[0, "desc"]],
      searching: true,
      paging: true,
      columns: columns,
      columnDefs: columnDefs,
      initComplete: function (settings, json) {
        $("table thead>tr>th.dt-head-center").each(function (index, ele) {
          $(ele).html("<div><span>" + $(ele).text() + "</span></div>");
        });
        //$('table thead>tr').css('height', '100px');
        //this.myTable.columns.adjust().draw();
      },
    });
  };

  componentWillUnmount() {
    if (this.myTable !== undefined) this.myTable.destroy(true);

    if (this.delayTimer !== undefined) clearTimeout(this.delayTimer);
  }

  reloadTableData = () => {
    this.myTable.ajax.reload();
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.ExtraParams != this.props.ExtraParams) {
      var nextE = JSON.parse(nextProps.ExtraParams);
      var thisE = JSON.parse(this.props.ExtraParams);
      if (nextE.View != thisE.View && nextE.View != JSON.parse(this.ExtraParams).View) {
        //console.log(">>>> distroy table & init again..");
        this.ExtraParams = nextProps.ExtraParams;
        if (this.myTable !== undefined) {
          this.myTable.clear().destroy();
          document.getElementById(this.props.id).innerHTML = "";
        }
        this.initDataTable(nextE.View, nextProps.HColumns);
      } else if (nextProps.HColumns != this.props.HColumns) {
        //console.log(">>>> distroy table & init again.. >> HColumns");
        this.ExtraParams = nextProps.ExtraParams;
        if (this.myTable !== undefined) {
          this.myTable.clear().destroy();
          document.getElementById(this.props.id).innerHTML = "";
        }
        this.initDataTable(nextE.View, nextProps.HColumns);
      } else {
        this.ExtraParams = nextProps.ExtraParams;
        this.reloadTableData();
      }
    }
    return false;
  }

  state = {
    search_current: "",
    search: "",
  };

  render() {
    return <table id={this.props.id} className="table table-hover"></table>;
  }
}

export default withRouter(DataTableCompDynamicP);

class DataTableDynamicDeal extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onRowClick: PropTypes.func,
    ExtraParams: PropTypes.string,
    makeAjaxRequest: PropTypes.func,
  };
  static defaultProps = {
    id: "gridtable",
  };
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.ExtraParams = this.props.ExtraParams;
    //console.log("inside dynamic grid P, componentDidMount, Props:", this.props);
    this.initDataTable();
  }

  initDataTable = () => {
    this.initDataTable1();

    $(".dataTables_filter input")
      .unbind()
      .bind(
        "input",
        function (e) {
          if (this.delayTimer !== undefined) clearTimeout(this.delayTimer);

          this.delayTimer = setTimeout(
            function () {
              if (this.state.search != this.state.search_current) {
                this.setState({ search_current: this.state.search });
                this.myTable.search(this.state.search).draw();
              }
            }.bind(this),
            1000
          );
          this.setState({ search: e.currentTarget.value });
          return;
        }.bind(this)
      );

    if (this.props.onRowClick !== undefined) {
      $("#" + this.props.id + " tbody").on(
        "click",
        "tr",
        function (e) {
          if (e.offsetX > 35) {
            var data = this.myTable.row(e.target).data();
            if (data !== undefined && this.props.onRowClick !== undefined) this.props.onRowClick(data);
          }
        }.bind(this)
      );
    }
  };

  initDataTable1 = () => {
    var columns = [
      { data: "dealRef", title: "Deal Ref" },
      { data: "dealName", title: "Deal" },
      { data: "registrationDate", title: "Registration Date" },
      { data: "pipeline", title: "Pipeline" },
      { data: "dealStage", title: "Milestone" },
      { data: "purchasePrice", title: "Offer Price" },
      { data: "grossCommission", title: "Gross Commission" },
    ];

    var columnDefs = [
      {
        render: function (data, type, row) {
          return deal_webref_format(data, row.dealID);
        },
        targets: 0,
      },
      {
        render: function (data, type, row) {
          return ToPriceString(data);
        },
        targets: 5,
      },
      {
        render: function (data, type, row) {
          return ToPriceString(data);
        },
        targets: 6,
      },
    ];
    const self = this; // Capture the context
    this.myTable = $("#" + this.props.id).DataTable({
      ajax: (requestParams, callback) => {
        this.props
          .makeAjaxRequest(requestParams)
          .then((response) => {
            //console.log(response);
            callback({
              data: response.data,
              recordsTotal: response.recordsTotal,
              recordsFiltered: response.recordsFiltered,
            });
          })
          .catch((error) => {
            console.error(error);
          });
      },
      responsive: true,
      processing: true,
      language: {
        infoFiltered: "",
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> ',
      },
      serverSide: true,
      ordering: true,
      order: [[0, "desc"]],
      searching: true,
      paging: true,
      columns: columns,
      columnDefs: columnDefs,
      createdRow: function (row, data, dataIndex) {
        $("td", row).on("click", function () {
          localStorage.setItem("dealSideMenuLink", "Deals");
          self.props.history.push("/deal/SaveDeal?ID=" + data.dealID);
        });
      },
    });
  };

  componentWillUnmount() {
    if (this.myTable !== undefined) this.myTable.destroy(true);

    if (this.delayTimer !== undefined) clearTimeout(this.delayTimer);
  }

  reloadTableData = () => {
    this.myTable.ajax.reload();
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.ExtraParams != this.props.ExtraParams) {
      var nextE = JSON.parse(nextProps.ExtraParams);
      var thisE = JSON.parse(this.props.ExtraParams);
      if (nextE.View != thisE.View && nextE.View != JSON.parse(this.ExtraParams).View) {
        //console.log(">>>> distroy table & init again..");
        this.ExtraParams = nextProps.ExtraParams;
        if (this.myTable !== undefined) {
          this.myTable.clear().destroy();
          document.getElementById(this.props.id).innerHTML = "";
        }
        this.initDataTable(nextE.View, nextProps.HColumns);
      } else if (nextProps.HColumns != this.props.HColumns) {
        //console.log(">>>> distroy table & init again.. >> HColumns");
        this.ExtraParams = nextProps.ExtraParams;
        if (this.myTable !== undefined) {
          this.myTable.clear().destroy();
          document.getElementById(this.props.id).innerHTML = "";
        }
        this.initDataTable(nextE.View, nextProps.HColumns);
      } else {
        this.ExtraParams = nextProps.ExtraParams;
        this.reloadTableData();
      }
    }
    return false;
  }

  state = {
    search_current: "",
    search: "",
  };

  render() {
    return <table id={this.props.id} className="table table-hover"></table>;
  }
}

export const DataTableCompDynamicD = withRouter(DataTableDynamicDeal);





class DataTableDynamicTask extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onRowClick: PropTypes.func,
    ExtraParams: PropTypes.string,
    makeAjaxRequest: PropTypes.func,
  };
  static defaultProps = {
    id: "gridtable",
  };
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.ExtraParams = this.props.ExtraParams;
    //console.log("inside dynamic grid P, componentDidMount, Props:", this.props);
    this.initDataTable();
  }

  initDataTable = () => {
    this.initDataTable1();

    $(".dataTables_filter input")
      .unbind()
      .bind(
        "input",
        function (e) {
          if (this.delayTimer !== undefined) clearTimeout(this.delayTimer);

          this.delayTimer = setTimeout(
            function () {
              if (this.state.search != this.state.search_current) {
                this.setState({ search_current: this.state.search });
                this.myTable.search(this.state.search).draw();
              }
            }.bind(this),
            1000
          );
          this.setState({ search: e.currentTarget.value });
          return;
        }.bind(this)
      );

    if (this.props.onRowClick !== undefined) {
      $("#" + this.props.id + " tbody").on(
        "click",
        "tr",
        function (e) {
          if (e.offsetX > 35) {
            var data = this.myTable.row(e.target).data();
            if (data !== undefined && this.props.onRowClick !== undefined) this.props.onRowClick(data);
          }
        }.bind(this)
      );
    }
  };

  initDataTable1 = () => {
    var columns = [
      { data: "dealRef", title: "Deal Ref" },
      { data: "dealName", title: "Deal", className: "" },
      { data: "stageName", title: "Milestone" },
      { data: "taskName", title: "Task" },
      { data: "assignedRoles", title: "Assigned Roles" },
      { data: "status", title: "Status" },
      { data: "stageExpected", title: "Milestone Expected" },
      { data: "statusUpdatedOn", title: "Last Updated" },
      { data: "taskCompletedDate", title: "Completed Date" },
    ];

    var columnDefs = [
      {
        render: function (data, type, row) {
          return deal_webref_format(data, row.dealId);
        },
        targets: 0,
      },
      {
        render: function (data, type, row) {
          return ToTaskStageString(data);
        },
        targets: 5,
      },
      {
        render: function (data, type, row) {
          return task_roles_format(data);
        },
        targets: 4,
      },
    ];

    const self = this;
    this.myTable = $("#" + this.props.id).DataTable({
      ajax: (requestParams, callback) => {
        this.props
          .makeAjaxRequest(requestParams)
          .then((response) => {
            //console.log(response);
            callback({
              data: response.data,
              recordsTotal: response.recordsTotal,
              recordsFiltered: response.recordsFiltered,
            });
          })
          .catch((error) => {
            console.error(error);
          });
      },
      responsive: true,
      processing: true,
      language: {
        infoFiltered: "",
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> ',
      },
      serverSide: true,
      ordering: true,
      order: [[0, "desc"]],
      searching: true,
      paging: true,
      columns: columns,
      columnDefs: columnDefs,
      createdRow: function (row, data, dataIndex) {
        $("td", row).on("click", function () {
          localStorage.setItem("dealSideMenuLink", "Tasks");
          self.props.history.push("/Deal/saveDeal?ID=" + data.dealId);

        });
      },
    });
  };

  componentWillUnmount() {
    if (this.myTable !== undefined) this.myTable.destroy(true);

    if (this.delayTimer !== undefined) clearTimeout(this.delayTimer);
  }

  reloadTableData = () => {
    this.myTable.ajax.reload();
  };

  shouldComponentUpdate(nextProps, nextState) {
    console.log("next Prop :: ", nextProps.ExtraParams);
    console.log("this Prop :: ", this.props.ExtraParams);

    if (nextProps.ExtraParams != this.props.ExtraParams) {
      var nextE = JSON.parse(nextProps.ExtraParams);
      var thisE = JSON.parse(this.props.ExtraParams);
      if (nextE.View != thisE.View && nextE.View != JSON.parse(this.ExtraParams).View) {
        //console.log(">>>> distroy table & init again..");
        this.ExtraParams = nextProps.ExtraParams;
        if (this.myTable !== undefined) {
          this.myTable.clear().destroy();
          document.getElementById(this.props.id).innerHTML = "";
        }
        this.initDataTable(nextE.View, nextProps.HColumns);
      } else if (nextProps.HColumns != this.props.HColumns) {
        //console.log(">>>> distroy table & init again.. >> HColumns");
        this.ExtraParams = nextProps.ExtraParams;
        if (this.myTable !== undefined) {
          this.myTable.clear().destroy();
          document.getElementById(this.props.id).innerHTML = "";
        }
        this.initDataTable(nextE.View, nextProps.HColumns);
      } else {
        this.ExtraParams = nextProps.ExtraParams;
        this.reloadTableData();
      }
    }
    return false;
  }

  state = {
    search_current: "",
    search: "",
  };

  render() {
    return <table id={this.props.id} className="table table-hover"></table>;
  }
}

export const DataTableCompDynamicT = withRouter(DataTableDynamicTask);

