import React, { useCallback } from "react";
import apiAgent from "../../../app/api/apiAgent";
import { VIEW } from "../constants";

const ContactPipeline = ({
    onClickMenu = () => { },
    pipelines = [],
    selectedPipeline = "",
    onLeadClick = () => { },
    view = VIEW.DEFAULT
}) => {
    const isDefaultPipeline = () => {
        return (!selectedPipeline && selectedPipeline === "" && pipelines.length > 0)
    }
    const onExportClick = () => {
        apiAgent.Contact.exportContactAjax()
            .then((response) => {
                var binaryData = [];
                //console.log(response);
                binaryData.push(response);
                window.URL.createObjectURL(new Blob(binaryData, { type: "text/csv" }));
                // Create blob link to download
                const url = window.URL.createObjectURL(new Blob(binaryData, { type: "application/octet-stream" }));

                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `contacts_export.csv`);

                // Append to html link element page
                document.body.appendChild(link);
                //console.log("link inside export", link);
                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            })

            .catch((error) => {
                console.log("error inside export", error);
            })
            .finally(() => { });
    };
    const generateMenu = useCallback(() => {
        if (!Array.isArray(pipelines)) {
            return null;
        }
        return (
            <>
                {
                    isDefaultPipeline() &&
                    (
                        <>
                            <div className="pipeline-container">
                                <i className="fa fa-list-alt" style={{ fontSize: "14px" }}></i>
                                <span className={`dashboard-menu`}>
                                    <b> {pipelines[0].text}</b>
                                </span>
                            </div>
                            <div className="pipeline-container" onClick={() => onExportClick()}>
                                <i className="fa fa-download" style={{ fontSize: "14px", cursor: "pointer" }}></i>
                                <span
                                    className={`dashboard-menu`}
                                >
                                    <i className="icon-class" style={{ marginRight: 8 }} />
                                    <span>Export</span>
                                </span>
                            </div>
                            <div className="pipeline-container" onClick={() => onLeadClick()}>
                                <i className="fa fa-hand-paper-o" style={{ fontSize: "14px", cursor: "pointer" }}></i>
                                <span
                                    className={`dashboard-menu`}
                                >
                                    <i className="icon-class" style={{ marginRight: 8 }} />

                                    {view === VIEW.LEAD && <b>Leads</b>}
                                    {view === VIEW.DEFAULT && <span>Leads</span>}
                                </span>
                            </div>
                        </>
                    )
                }
                {
                    isDefaultPipeline() &&
                    (
                        pipelines?.slice(1)?.map((pipeline, index) => {
                            const isLastPipeline = index === pipelines.length - 1;
                            return (
                                <>
                                    <div className="pipeline-container" onClick={() => onClickMenu(pipeline.value)}>
                                        <i className="fa fa-list-alt" style={{ fontSize: "14px", cursor: "pointer" }}></i>

                                        <span
                                            key={pipeline.value}
                                            className={`dashboard-menu`}

                                        >
                                            <i className="icon-class" style={{ marginRight: 8 }} />

                                            {pipeline.value === selectedPipeline ? (
                                                <b>{pipeline.text}</b>
                                            ) : (
                                                <span>{pipeline.text}</span>
                                            )}
                                        </span>
                                    </div>
                                    {
                                        isLastPipeline && (
                                            <>
                                                <div className="pipeline-container" onClick={() => onExportClick()}>
                                                    <i className="fa fa-download" style={{ fontSize: "14px", cursor: "pointer" }}></i>
                                                    <span
                                                        className={`dashboard-menu`}
                                                    >
                                                        <i className="icon-class" style={{ marginRight: 8 }} />
                                                        <span>Export</span>
                                                    </span>
                                                </div>
                                                <div className="pipeline-container" onClick={() => onLeadClick()}>
                                                    <i className="fa fa-hand-paper-o" style={{ fontSize: "14px", cursor: "pointer" }}></i>
                                                    <span
                                                        className={`dashboard-menu`}
                                                    >
                                                        <i className="icon-class" style={{ marginRight: 8 }} />
                                                        {view === VIEW.LEAD && <b>Leads</b>}
                                                        {view === VIEW.DEFAULT && <span>Leads</span>}
                                                    </span>
                                                </div>
                                            </>
                                        )
                                    }
                                </>
                            )
                        })
                    )
                }
                {
                    !isDefaultPipeline() &&
                    (
                        pipelines?.map((pipeline, index) => {
                            const isLastPipeline = index === pipelines.length - 1;

                            return (
                                <>
                                    <div className="pipeline-container" onClick={() => onClickMenu(pipeline.value)}>
                                        <i className="fa fa-list-alt" style={{ fontSize: "14px", cursor: "pointer" }}></i>

                                        <span
                                            key={pipeline.value}
                                            className={`dashboard-menu`}

                                        >
                                            <i className="icon-class" style={{ marginRight: 8 }} />

                                            {pipeline.value === selectedPipeline ? (
                                                <b>{pipeline.text}</b>
                                            ) : (
                                                <span>{pipeline.text}</span>
                                            )}
                                        </span>


                                    </div>
                                    {
                                        isLastPipeline && (
                                            <>
                                                <div className="pipeline-container" onClick={() => onExportClick()}>
                                                    <i className="fa fa-download" style={{ fontSize: "14px", cursor: "pointer" }}></i>
                                                    <span
                                                        className={`dashboard-menu`}
                                                    >
                                                        <i className="icon-class" style={{ marginRight: 8 }} />
                                                        <span>Export</span>
                                                    </span>
                                                </div>
                                                <div className="pipeline-container" onClick={() => onLeadClick()}>
                                                    <i class="fa fa-hand-paper-o" style={{ fontSize: "14px", cursor: "pointer" }}></i>
                                                    <span
                                                        className={`dashboard-menu`}
                                                    >
                                                        <i className="icon-class" style={{ marginRight: 8 }} />
                                                        {view === VIEW.LEAD && <b>Leads</b>}
                                                        {view === VIEW.DEFAULT && <span>Leads</span>}
                                                    </span>
                                                </div>
                                            </>
                                        )
                                    }
                                </>
                            )
                        })

                    )
                }
            </>
        )
    });

    return (
        <>
            <div className="container-menu-link">
                {/* Menu for Small Screens */}
                <div className="menu-small col-sm-2">
                    <div style={{ padding: "0px 10px 0px 0px", marginLeft: "-30px" }} className="menu-container">
                        {generateMenu()}


                    </div>
                </div>

                {/* Menu for Medium Screens and up */}
                <div className="menu-large col-md-1">
                    <div style={{ padding: "10px 10px 0px 0px", marginLeft: "-30px" }} className="menu-container">
                        {generateMenu()}


                    </div>
                </div>
            </div >


        </>
    );
}

export default ContactPipeline;