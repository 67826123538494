import { Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import apiAgent from "../../app/api/apiAgent";

import FormikControl from "../../app/common/common-components/Formik/FormikControl";
import { OMSButtonStyleL } from "../../app/common/common-components/OMSButton";
import { OMSFavourite } from "../../app/common/common-components/OMSFavourite";
import { OMSContactBoxView } from "../../app/common/common-components/OMSSocialBox";
import { ContactContactS } from "../../app/models/ContactContactS";
import { ContactModel } from "../../app/models/ContactModel";
import { AuditTrailBox } from "./AuditTrailBox";

import ContactOverview from "./SaveContactTabs/ContactOverview";
import ContactTab from "./SaveContactTabs/ContactTab";
import Diary from "./SaveContactTabs/Diary";
import MessageTab from "./SaveContactTabs/MessageTab";
import PersonalTab from "./SaveContactTabs/PersonalTab";
import Qualify from "./SaveContactTabs/Qualify";
import Referral from "./SaveContactTabs/Referral";
import SaveContactFooter from "./SaveContactTabs/SaveContactFooter";
import { pipeline } from "stream";

export interface ContactTabCustomControlValues {
  facebook: string;
  home_dialing_code: string;
  home_phone: string;
  instagram: string;
  linkedin: string;
  office_dialing_code: string;
  office_phone: string;
  phone: string;
  phone_dialing_code: string;
  twitter: string;
  residential_address: string;
  postal_address: string;
  emailAddress: string;
}

export interface PersonalTabCustomValues {
  leadSource: string;
  sharing: number;
  title: string;
  birthDate: Date | null;
  status: number;
  gender: string;
  contactType: string;
  firstName: string;
  lastName: string;
  companyName: string;
  nationalIDNumber: string;
  photoUpload: string;
  pipelineId: string;
  stageId: string;
}

export interface ButtonStatus {
  disabled: boolean,
  processing_save: boolean,
  processing_delete: boolean,
}

function ContactBox(props: any) {

  const getCustomPersonalTabValues = (contact: ContactModel) => {
    let tempValues: PersonalTabCustomValues;
    if (contact.contactID !== "") {
      tempValues = {
        leadSource: contact.leadSource,
        sharing: contact.sharing,
        title: contact.title,
        birthDate: contact.birthDate,
        status: contact.status,
        gender: String(contact.gender),
        contactType: String(contact.contactType),
        firstName: contact.firstName,
        lastName: contact.lastName,
        companyName: contact.companyName,
        nationalIDNumber: contact.nationalIDNumber,
        photoUpload: contact.photoUpload,
        pipelineId: contact?.pipelineId,
        stageId: contact?.stageId
      };
    } else {
      tempValues = {
        //initialize all with default values
        leadSource: "",
        sharing: 1,
        title: "",
        birthDate: null,
        status: 1,
        gender: "2",
        contactType: "1",
        firstName: "",
        lastName: "",
        companyName: "",
        nationalIDNumber: "",
        photoUpload: "",
        pipelineId: "",
        stageId: ""
      };
    }
    return tempValues;
  };

  const getCustomContactTabValues = (contact: ContactModel) => {
    let mContactTemp: any = contact.mContact;

    let tempContact: ContactTabCustomControlValues;
    // if (contact.contactID !== "") {
    tempContact = {
      facebook: mContactTemp.facebook,
      home_dialing_code: mContactTemp.home_dialing_code,
      home_phone: mContactTemp.home_phone,
      instagram: mContactTemp.instagram,
      linkedin: mContactTemp.linkedin,
      office_dialing_code: mContactTemp.office_dialing_code,
      office_phone: mContactTemp.office_phone,
      phone: mContactTemp.phone,
      phone_dialing_code: mContactTemp.phone_dialing_code,
      twitter: mContactTemp.twitter,
      residential_address: mContactTemp.residential_address,
      postal_address: mContactTemp.postal_address,
      emailAddress: contact.emailAddress,
    };
    //} 
    // else {
    //   tempContact = {
    //     facebook: "",        
    //     home_dialing_code: mContactTemp.home_dialing_code,
    //     home_phone: "",
    //     instagram: "",
    //     linkedin: "",
    //     office_dialing_code: "",
    //     office_phone: "",
    //     phone: "",
    //     phone_dialing_code: "",
    //     twitter: "",
    //     residential_address: "",
    //     postal_address: "",
    //     emailAddress: "",
    //   };
    // }
    return tempContact;
  };

  const getUserDefinedTags = (selectedItems: any) => {
    var items = [];
    var selectedItemsL = selectedItems.map((I: string) => I.toLowerCase());
    for (var i = 0; i < props.contact!.userTags!.length; i++) {
      if (selectedItemsL.indexOf(props.contact!.userTags![i].name.toLowerCase()) > -1)
        items.push({
          name: props.contact!.userTags![i].name,
          color: props.contact!.userTags![i].color,
          selected: true,
        });
      else
        items.push({
          name: props.contact!.userTags![i].name,
          color: props.contact!.userTags![i].color,
          selected: false,
        });
    }
    return items;
  };

  const getOfficeDefaultTags = (selectedItems: any) => {

    var items = [];
    var selectedItemsL = selectedItems.map((I: string) => I.toLowerCase());
    var linkedListingJSON = props.contact!.linkedListingJSON ? JSON.parse(props.contact!.linkedListingJSON) : [];
    var linkedListingTagJSON = props.contact!.linkedListingJSON ? JSON.parse(props.contact!.linkedListingJSON).map((I: any) => I.ContactType) : [];
    var linkedListingIncludedTagJSON = [];

    for (var i = 0; i < props.contact!.officeTags!.length; i++) {
      var item = {
        name: props.contact!.officeTags![i].name,
        color: props.contact!.officeTags![i].color,
        selected: false,
        linked: false,
        linkedobj: {},
      };
      if (selectedItemsL.indexOf(props.contact!.officeTags![i].name.toLowerCase()) > -1) item.selected = true;

      var index = linkedListingTagJSON.indexOf(props.contact!.officeTags![i].name.toLowerCase());
      if (index > -1) {
        item.linked = true;
        item.linkedobj = linkedListingJSON[index];
        linkedListingIncludedTagJSON.push(linkedListingTagJSON[index]);
      }
      items.push(item);
    }

    if (linkedListingTagJSON.length != linkedListingIncludedTagJSON.length) {
      for (var i = 0; i < linkedListingTagJSON.length; i++) {
        if (linkedListingIncludedTagJSON.indexOf(linkedListingTagJSON[i]) == -1) {
          var item2 = {
            name: linkedListingTagJSON[i],
            color: "#777",
            selected: false,
            linked: true,
            linkedobj: linkedListingJSON[i],
          };
          items.push(item2);
        }
      }
    }

    items.sort((I: any) => I.linked);
    items.reverse();

    return items;
  };

  const defaultButtonStatus: ButtonStatus = {
    disabled: false,
    processing_save: false,
    processing_delete: false,
  }



  //let isSaveButtonClicked = false;
  const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false);
  const [buttonStatus, setButtonStatus] = useState<ButtonStatus>(defaultButtonStatus);
  const [customMContact, setCustomMContact] = useState<ContactTabCustomControlValues>(getCustomContactTabValues(props.contact!));
  const [customPersonalTabValues, setCustomPersonalTabValues] = useState<PersonalTabCustomValues>(getCustomPersonalTabValues(props.contact!));
  const [mContact, setMContact] = useState(props.contact!.mContact);
  const [step, setStep] = useState<number>(props.contact!.contactID == "" ? 1 : 0);
  const [pinnedNotes, setPinnedNotes] = useState<any>();
  const [pinDisabled, setPinDisabled] = useState();
  //const [contact, setContact] = useState(props.contact!.mContact);
  const [userDefinedTags, setUserDefinedTags] = useState(getUserDefinedTags(props.contact!.tags));
  const [officeDefaultTags, setOfficeDefaultTags] = useState(getOfficeDefaultTags(props.contact!.tags));
  const [status, setStatus] = useState(props.contact!.status);
  const [leadSource, setLeadSource] = useState(props.contact!.leadSource);
  const [isShowAuditTrail, setIsShowAuditTrail] = useState(false);
  const [isDraft, setIsDraft] = useState(false);

  const contactID = props.contact!.contactID;
  const diary = props.contact!.diary;
  const history = useHistory();

  const getPinnedNotes = () => {
    let ID = props.contact!.contactID;
    let DNType = 1; //1. Contact 2. Property

    apiAgent.Diary.getPinnedNotesAjax(ID, props.contact!.UserId, DNType)
      .then((response: any) => {
        setPinnedNotes(response);
      })
      .catch((error: any) => toast.error("Failed to get pinned notes"));
  };

  const handleIsDraft = (value: boolean) => {
    setIsDraft(value);
    doSave();
  };

  const handleAuditTrailClose = () => {
    //this.setState({ IsShowAuditTrail: false });
    setIsShowAuditTrail(false);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let stepView = 0;

    if (contactID) {
      getPinnedNotes();
    }
    if (params.has("Step")) {
      const step = params.get("Step");
      stepView = step !== null ? Number(step) : 0;
      setStep(stepView);
      // Remove the "Step" parameter from the URL
      params.delete("Step");

      // Update the URL without reloading the page
      window.history.replaceState(null, "", `${window.location.pathname}?${params.toString()}`);

    }

  }, []);

  var stepName = props.contact!.displayName;
  if (contactID == "") {
    if (step == 1) stepName = "Create contact: Personal";
    else if (step == 2) stepName = "Create contact: Contact";
    else if (step == 3) stepName = "Create contact: Address";
  }

  const onCancelClick = (e: any) => {
    history.push("/contacts");
    //location.href = "/Contacts";
  };

  const checkFormData = (values: ContactModel) => {
    var result = { status: "error", message: "Fields are required." };
    var resultSuccess = { status: "success", message: "" };

    let { contactType, firstName, lastName, companyName } = values;

    if (contactType == "2") {
      if (!companyName.trim()) {
        result.message = "Please enter company name";
        return result;
      }
    } else {
      if (!firstName.trim()) {
        result.message = "Please enter first name";
        return result;
      }
      if (!lastName.trim()) {
        result.message = "Please enter last name";
        return result;
      }
    }
    return resultSuccess;
  };

  const doSave = () => {
    //setIsSaveButtonClicked(true);
    //isSaveButtonClicked = true;
    setIsSaveButtonClicked(true);
    // var validationResult = checkFormData(values);
    // if (validationResult.status == "error") {
    //   toast.info(validationResult.message);
    // } else {
    //   values.mContact.residentialAddress = values.residentialAddressFormField;
    //   values.mContact.postalAddress = values.postalAddressFormField;
    //   values.birthDate = birthDate;
    //   values.sharing = sharing;
    //   values.status = status;
    //   values.leadSource = leadSource;

    //   values.title = title;
    //   values.photoUpload = photoUpload;
    //   setButtonStatus({
    //     disabled: true,
    //     processing_save: true,
    //     processing_delete: false,
    //   });
    //   apiAgent.Contact.saveContactAjax(values)
    //     .then((response: any) => {
    //       history.push("/contacts");
    //     })
    //     .catch((error: any) => toast.info("Fail"))
    //     .finally(() => {
    //       setButtonStatus({
    //         disabled: false,
    //         processing_save: false,
    //         processing_delete: false,
    //       });
    //     });
    //}
  };

  const doChangeStepClick = (step: any) => {
    setStep(step);
    var interval;
    // if (step == 6) {
    //   refreshMessageGrid(step);
    //   interval = setInterval(refreshMessageGrid, 90000);
    // } else clearInterval(interval);
  };
  const unpinNote = (note: any) => {
    // needs to be checked

    // this.setState({ ...this.state, pinDisabled: note.diaryID });
    setPinDisabled(note.diaryID);
    /* var changedItem = note;
        changedItem.pinned = false;
    
        var data = new FormData();
    
        Object.keys(changedItem).forEach(function (key) {
            data.append(key, changedItem[key]);
        });
    
        var xhr = new XMLHttpRequest();
        xhr.open('post', '/Diary/UpdateDiaryAjax', true);
        xhr.onload = function () {
            var data = JSON.parse(xhr.responseText);
            if (data.status == "success") {
                var updatePinnedNotes = this.state.pinnedNotes.filter((item) => item.diaryID !== changedItem.diaryID);
                this.setState({ ...this.state, pinnedNotes: updatePinnedNotes, pinDisabled: '' });
                if(this.noteCalenderRef.current !== undefined)
                    this.noteCalenderRef.current.onDiaryItemSubmit();
            } else {
                this.setState({ ...this.state, pinDisabled: '' });
            }
        }.bind(this);
        xhr.send(data); */
  };

  const initialValues = {
    contactID: props.contact!.contactID,
    userID: props.contact!.userID,
    assigned: props.contact!.assigned,
    contactType: String(props.contact!.gender),
    companyName: props.contact!.companyName,
    title: props.contact!.title,
    firstName: props.contact!.firstName,
    lastName: props.contact!.lastName,
    emailAddress: props.contact.contactID === "" ? "" : props.contact!.emailAddress,
    sharing: props.contact!.sharing,
    birthDate: props.contact!.birthDate,
    //sharing: sharing,
    //birthDate: isoBirthDate,
    nationalIDNumber: props.contact!.nationalIDNumber,
    gender: String(props.contact!.gender),
    status: 0,
    leadSource: props.contact!.leadSource,
    tags: props.contact!.tags,
    photo: "",
    photoUpload: "",
    photoUrl: "",
    claimedBy: "",
    sinceLastActivity: "",
    linkedListingJSON: "",
    contact: "",
    dialingCode: props.contact!.dialingCode,
    displayName: "",
    allowedDialingCodes: props.contact!.allowedDialingCodes,
    officeID: "",
    super_access: props.contact!.super_access,
    residentialAddressFormField: props.contact!.mContact!.residential_address,
    postalAddressFormField: props.contact!.mContact!.postalAddress,
    upcommingEvents: props.contact!.upcommingEvents,
    diary: props.contact!.diary,
    mContact: props.contact!.mContact,
    residentialAddress: props.contact.contactID === "" ? "" : props.contact!.mContact!.residential_address,
    postalAddress: props.contact.contactID === "" ? "" : props.contact!.mContact!.postalAddress,
    pipelineId: props.contact.pipelineId,
    stageId: props.contact.stageId
  };

  // const mapCustomValuesFromContactTab = (values: ContactModel,customMContact:ContactTabCustomControlValues) => {

  //   values. = customMContact;
  //   return values;

  // };

  const onSubmit = (values: ContactModel) => {
    if (!isSaveButtonClicked) {
      return;
    }

    values.mContact.facebook = customMContact.facebook;
    values.mContact.twitter = customMContact.twitter;
    values.mContact.instagram = customMContact.instagram;
    values.mContact.linkedin = customMContact.linkedin;
    values.mContact.home_dialing_code = customMContact.home_dialing_code;
    values.mContact.home_phone = customMContact.home_phone;
    values.mContact.office_dialing_code = customMContact.office_dialing_code;
    values.mContact.office_phone = customMContact.office_phone;
    values.mContact.phone_dialing_code = customMContact.phone_dialing_code;
    values.mContact.phone = customMContact.phone;
    values.mContact.residential_address = customMContact.residential_address;
    values.mContact.postal_address = customMContact.postal_address;
    values.emailAddress = customMContact.emailAddress;
    //values.mContact.postalAddress=values.mContact.postal_address;

    // let mContact: ContactContactS = {
    //   facebook: customMContact.facebook,
    //   twitter: customMContact.twitter,
    //   instagram: customMContact.instagram,
    //   linkedin: customMContact.linkedin,
    //   homeDialingCode: customMContact.home_dialing_code,
    //   homePhone: customMContact.home_phone,
    //   officeDialingCode: customMContact.office_dialing_code,
    //   officePhone: customMContact.office_phone,
    //   dialingCode: customMContact.phone_dialing_code,
    //   phone: customMContact.phone,
    //   residentialAddress: customMContact.residential_address,
    //   postalAddress: customMContact.postal_address,
    // };
    values.leadSource = customPersonalTabValues.leadSource;
    values.sharing = customPersonalTabValues.sharing;
    values.title = customPersonalTabValues.title;
    values.birthDate = customPersonalTabValues.birthDate;
    values.status = customPersonalTabValues.status;
    values.gender = customPersonalTabValues.gender;
    values.contactType = customPersonalTabValues.contactType;
    values.firstName = customPersonalTabValues.firstName;
    values.lastName = customPersonalTabValues.lastName;
    values.companyName = customPersonalTabValues.companyName;
    values.nationalIDNumber = customPersonalTabValues.nationalIDNumber;
    values.photoUpload = customPersonalTabValues.photoUpload;
    values.pipelineId = customPersonalTabValues.pipelineId;
    values.stageId = customPersonalTabValues.stageId;


    //   firstName: customPersonalTabValues.firstName,
    //   lastName: customPersonalTabValues.lastName,
    //   companyName: customPersonalTabValues.companyName,
    //   nationalIDNumber: customPersonalTabValues.nationalIDNumber,

    // let values: ContactModel = {
    //   contactID: props.contact!.contactID,
    //   userID: props.contact!.userID,
    //   assigned: props.contact!.assigned,
    //   mContact: mContact,
    //   emailAddress: customMContact.emailAddress,
    //   leadSource: customPersonalTabValues.leadSource,
    //   sharing: customPersonalTabValues.sharing,
    //   title: customPersonalTabValues.title,
    //   birthDate: customPersonalTabValues.birthDate,
    //   status: customPersonalTabValues.status,
    //   gender: customPersonalTabValues.gender,
    //   contactType: customPersonalTabValues.contactType,
    //   firstName: customPersonalTabValues.firstName,
    //   lastName: customPersonalTabValues.lastName,
    //   companyName: customPersonalTabValues.companyName,
    //   nationalIDNumber: customPersonalTabValues.nationalIDNumber,
    // };

    // need to check with values object
    var validationResult = checkFormData(values);

    if (validationResult.status == "error") {
      toast.info(validationResult.message);
    } else {
      //Rohit: To be implemented later

      //values.photoUpload = photoUpload;

      if (isSaveButtonClicked) {
        setButtonStatus({
          disabled: true,
          processing_save: true,
          processing_delete: false,
        });
        apiAgent.Contact.saveContactAjax(values)
          .then((response: any) => {
            if (isDraft) {
              toast.info("contact data saved successfully");
            }
            else {
              history.push("/contacts");
            }
          })
          .catch((error: any) => toast.info("Fail"))
          .finally(() => {
            setButtonStatus({
              disabled: false,
              processing_save: false,
              processing_delete: false,
            });
            setIsSaveButtonClicked(false);

          });
      }
    }
  };

  const renderDiaryItems = () => {
    if (diary.DiaryID == "")
      return (
        <span>no future diary events for this listing. {diary.diaryTitle}</span>
      )
    else
      return (
        <span><b>{diary.typeID} </b>{diary.startDate} {diary.contactName}</span>
      );
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {(formik) => {
        return (
          <Form className="SaveContactForm">
            <div className="col-sm-12">
              <div className={contactID !== "" ? "panel panel-default oms-box color2 color2-border-right" : "panel panel-default oms-box"}>
                <div className="wrap">
                  <div className="left">
                    <div className="panel-heading">
                      <div className="row">
                        <div className="col-md-12">
                          <OMSFavourite favourite={props.contact!.favourite} /> &nbsp; {stepName}
                        </div>
                      </div>
                    </div>
                    <div className="panel-body" style={{ backgroundColor: "white", minHeight: 600 }}>
                      <div className="col-md-12">
                        {contactID &&
                          <div id="overview" style={{ display: step == 0 ? "" : "none" }}>
                            <ContactOverview
                              diary={diary}
                              pinnedNotes={pinnedNotes}
                              unpinNote={unpinNote}
                              pinDisabled={pinDisabled}
                              leadSource={leadSource}
                              contact={props.contact}
                              userDefinedTags={userDefinedTags}
                              officeDefaultTags={officeDefaultTags}
                              status={status}
                              mContact={props.contact!.mContact}
                              renderDiaryItems={renderDiaryItems}
                            />
                          </div>}
                        {contactID &&
                          <div id="diary" style={{ display: step == 4 ? "" : "none" }}>
                            <Diary contact={props.contact} getPinnedNotes={getPinnedNotes} />
                          </div>
                        }
                        <div id="personal" style={{ display: step == 1 ? "" : "none" }}>
                          <PersonalTab
                            contact={props.contact}
                            customPersonalTabValues={customPersonalTabValues}
                            setCustomPersonalTabValues={setCustomPersonalTabValues}
                          />
                        </div>
                        <div id="contact" style={{ display: step == 2 ? "" : "none" }}>
                          <ContactTab contact={props.contact} customMContact={customMContact} setCustomMContact={setCustomMContact} />
                        </div>
                        <div id="address" style={{ display: step == 3 ? "" : "none" }}>
                          <Referral contact={props.contact} />
                        </div>
                        {contactID &&
                          <div id="qualify" style={{ display: step == 5 ? "" : "none" }}>
                            <Qualify contact={props.contact} />
                          </div>}
                        {contactID &&
                          <div id="message" style={{ display: step == 6 ? "" : "none" }}>
                            <MessageTab contact={props.contact} />
                          </div>}
                      </div>
                    </div>
                    <div className="panel-footer">
                      <SaveContactFooter
                        contact={props.contact}
                        step={step}
                        doChangeStepClick={doChangeStepClick}
                        doSave={doSave}
                        onCancelClick={onCancelClick}
                        setIsShowAuditTrail={setIsShowAuditTrail}
                        buttonStatus={buttonStatus}
                        setButtonStatus={setButtonStatus}
                        handleIsDraft={handleIsDraft}
                      />
                    </div>
                  </div>
                  {contactID == "" ? null : (
                    <div className="right color2 hidden-xs hidden-sm">
                      <div className="row">
                        <div className="col-md-12">
                          <a
                            className={step == 0 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                            onClick={() => doChangeStepClick(0)}
                          >
                            <span>
                              <i className="fa fa-tachometer fa-2x"></i>
                              <br />
                              Overview
                            </span>
                          </a>
                          <a
                            className={step == 4 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                            onClick={() => doChangeStepClick(4)}
                          >
                            <span>
                              <i className="fa fa-clock-o fa-2x"></i>
                              <br />
                              Diary
                            </span>
                          </a>
                          <a
                            className={step == 1 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                            onClick={() => doChangeStepClick(1)}
                          >
                            <span>
                              <i className="fa fa-user-circle fa-2x"></i>
                              <br />
                              Personal
                            </span>
                          </a>
                          <a
                            className={step == 2 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                            onClick={() => doChangeStepClick(2)}
                          >
                            <span>
                              <i className="fa fa-phone fa-2x"></i>
                              <br />
                              Contact
                            </span>
                          </a>
                          {props.contact!.super_access ? (
                            <a
                              className={step == 3 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                              onClick={() => doChangeStepClick(3)}
                            >
                              <span>
                                <i className="fa fa-handshake-o fa-2x"></i>
                                <br />
                                Referral
                              </span>
                            </a>
                          ) : null}
                          <a
                            className={step == 5 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                            onClick={() => doChangeStepClick(5)}
                          >
                            <span>
                              <i className="fa fa-filter fa-2x"></i>
                              <br />
                              Qualify
                            </span>
                          </a>
                          <a
                            className={step == 6 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                            onClick={() => doChangeStepClick(6)}
                          >
                            <span>
                              <i className="fa fa-envelope-o fa-2x"></i>
                              <br />
                              Message
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                  {contactID == "" ? null : (
                    <div className="rightS color2 hidden-md hidden-lg">
                      <div className="row">
                        <div className="col-md-12">
                          <a
                            className={step == 0 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                            onClick={() => doChangeStepClick(0)}
                          >
                            <i className="fa fa-tachometer fa-2x"></i>
                          </a>
                          <a
                            className={step == 4 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                            onClick={() => doChangeStepClick(4)}
                          >
                            <i className="fa fa-clock-o fa-2x"></i>
                          </a>
                          <a
                            className={step == 1 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                            onClick={() => doChangeStepClick(1)}
                          >
                            <i className="fa fa-user-circle fa-2x"></i>
                          </a>
                          <a
                            className={step == 2 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                            onClick={() => doChangeStepClick(2)}
                          >
                            <i className="fa fa-phone fa-2x"></i>
                          </a>
                          {props.contact!.super_access ? (
                            <a
                              className={step == 3 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                              onClick={() => doChangeStepClick(3)}
                            >
                              <i className="fa fa-handshake-o fa-2x"></i>
                            </a>
                          ) : null}
                          <a
                            className={step == 5 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                            onClick={() => doChangeStepClick(5)}
                          >
                            <i className="fa fa-filter fa-2x"></i>
                          </a>
                          <a
                            className={step == 6 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                            onClick={() => doChangeStepClick(6)}
                          >
                            <i className="fa fa-envelope-o fa-2x"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {isShowAuditTrail === true && (
              <AuditTrailBox id="auditProperty" objectID={contactID} officeID={props.contact!.officeID} onClose={handleAuditTrailClose}></AuditTrailBox>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

export default ContactBox;
