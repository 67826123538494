import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ContactCard from "./ContactCard";
import { LABEL, VIEW_TYPE } from "../constants";
import { useRef, useState } from "react";

const ContactMilestone = ({
    actions = [],
    milestoneMaxHeight = 0,
    onClickSendEmail = () => { },
    onDragEnd = () => { },
    onWhatsappClick = () => { },
    stages = [],
    tagColorFormatter = () => { },
    onClickNewActivity = () => { },
    viewType = 0,

}) => {
    const cardRef = useRef();
    const isErrorContactsEmpty = stages.every((item) => item.contactCount === 0);
    const [clickedContactId, setClickedContactId] = useState(0);

    const onDragStart = () => {
        setClickedContactId(0);
    }
    return (
        <>
            {(
                <div className="milestone-drag-container mt-2" style={{ overflow: isErrorContactsEmpty ? "hidden" : "auto", height: isErrorContactsEmpty && "7vh" }}>
                    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                        {stages?.length > 0
                            && stages?.map((stage, index) => {

                                return (
                                    <>

                                        <div className="milestone-column" key={stage.stageID}>
                                            <Droppable droppableId={stage.stageID}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                    >
                                                        <div className="column-wrapper sticky-milestone-header" key={index}>
                                                            <div className="column-header-wrapper">
                                                                <span className="milestone-title"><strong>{stage?.stageName}</strong></span>
                                                                <span className="milestone-badge badge">{stage?.contacts?.length}</span>
                                                            </div>
                                                        </div>
                                                        <div className={`column-body-wrapper ${snapshot.isDraggingOver ? 'dragging-milestone' : ''}`} style={{ height: milestoneMaxHeight + "px" }}>
                                                            <div className="dragDropInner" id={`drag_drop_container_${index}`}>
                                                                {stage.contacts.map((contactItem, index) => {
                                                                    return (
                                                                        <Draggable key={contactItem.contactID}
                                                                            draggableId={contactItem.contactID}
                                                                            index={index}
                                                                        >
                                                                            {(provided) => (
                                                                                <>
                                                                                    <ContactCard
                                                                                        clickedContactId={clickedContactId}
                                                                                        contactItem={contactItem}
                                                                                        index={index}
                                                                                        onClickNewActivity={onClickNewActivity}
                                                                                        onClickSendEmail={onClickSendEmail}
                                                                                        onWhatsappClick={onWhatsappClick}
                                                                                        provided={provided}
                                                                                        ref={cardRef}
                                                                                        setClickedContactId={setClickedContactId}
                                                                                        snapshot={snapshot}
                                                                                        tagColorFormatter={tagColorFormatter}
                                                                                        viewType={viewType}
                                                                                    />

                                                                                </>
                                                                            )}
                                                                        </Draggable>
                                                                    )
                                                                })}

                                                                <div style={{ height: "100px" }}>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        {provided.placeholder}

                                                    </div>
                                                )}
                                            </Droppable>

                                        </div>


                                    </>
                                )
                            })
                        }
                    </DragDropContext>

                </div>
            )
            }
            {
                isErrorContactsEmpty &&
                (<div class="message-container">
                    <h4>No contacts found matching the provided search criteria. Please refine your search and try again.</h4>
                </div>)
            }
        </>
    );
}

export default ContactMilestone
