import { observer } from "mobx-react";
import { useStore } from "../../app/stores/store";
import HeaderWrapper from "../header/HeaderWrapper";
import Breadcrumb from "../../app/common/common-components/Breadcrumb";
import ContactBoard from "./contactBoard/ContactBoard";

export default observer(function ContactPage() {
  const {
    userStore: { userSession, logout, isLoggedIn },
  } = useStore();  
  document.title = "Base - Contacts";
  return (
      <>
        {/* <Header /> */}
        <HeaderWrapper />
        <div className="contact-page container-fluid">
          

          <div className="row">
            <div id="content" className="col-sm-12 col-md-12">             
              <ContactBoard  />
            </div>
          </div>
        </div>
      </>
    );
  
});
