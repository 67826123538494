import { useRef, useState } from "react";
import { OMSMultiselectTagDropdown2 } from "../../../app/common/common-components/OMSMultiselectTagDropdown";
import { useHistory } from "react-router";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import { CLASS_NAME, CONTACT_CLASS_NAME, CONTACT_VIEW_TYPE, LABEL, LEAD_ACTION_TYPE, LEAD_TYPE, VIEW, VIEW_TYPE } from "../constants";
import { OMSDropdown } from "../../../app/common/common-components/OMSDropdown";

const ContactFilter = ({
    actions = [],
    contactType = 0,
    officeTags = [],
    onChangeContactViewType = () => { },
    onChangeTags = () => { },
    onChangeViewType = () => { },
    onChangeSeachText = () => { },
    onChangeLeads = () => { },
    sourceTypesTag = [],
    userTags = [],
    viewType = 0,
    searchTerm = "",
    view = VIEW.DEFAULT,
    usersDD = [],
    mLeads = 0,
    mLeadType = LEAD_TYPE.ALL,
    mLeadActionType = LEAD_ACTION_TYPE.NEW,
    onChangeLeadActionType = () => { },
    onChangeLeadType = () => { },
}) => {
    const history = useHistory();
    const [tags, setTags] = useState([]);
    const [sources, setSources] = useState([]);
    const refSearchBox = useRef();

    const viewTypeOptions = [
        { Value: VIEW_TYPE.LIST, Text: LABEL.LIST },
        { Value: VIEW_TYPE.CARD_SUMMARY, Text: LABEL.CARD_SUMMARY },
        { Value: VIEW_TYPE.CARD_FULL, Text: LABEL.CARD_FULL },
    ];
    const contactViewTypeOptions = [
        { Value: CONTACT_VIEW_TYPE.MY_CONTACTS, Text: LABEL.MY_CONTACTS },
        { Value: CONTACT_VIEW_TYPE.ALL_CONTACTS, Text: LABEL.ALL_CONTACTS },
    ];
    const leadTypeOptions = [
        { Value: LEAD_TYPE.UN_READ, Text: LABEL.INBOX_UN_READ },
        { Value: LEAD_TYPE.READ, Text: LABEL.INBOX_READ },
        { Value: LEAD_TYPE.ALL, Text: LABEL.INBOX_ALL },
    ];



    const handleInputChange = () => {
        const searchText = refSearchBox.current.value;
        onChangeSeachText(searchText);
    };
    const onClickSeachReset = () => {
        onChangeSeachText("");
        refSearchBox.current.focus();
    }

    const handleViewTypeChange = (e) => {
        onChangeViewType(Number(e.newValue));
    }
    const handleContactViewTypeChange = (e) => {
        onChangeContactViewType(Number(e.newValue));
    }

    return (
        <>
            <div className="filter-container">
                {
                    view !== VIEW.LEAD && (
                        <div className="contact-view-type-control"
                            style={{
                                marginRight: "10px",
                                marginTop: "7px"
                            }}>

                            <OMSDropdown
                                id="ContactViewTypeDD"
                                options={contactViewTypeOptions}
                                valueField="Value"
                                labelField="Text"
                                value={contactType}
                                onChange={handleContactViewTypeChange}
                            />
                        </div>
                    )
                }
                {
                    view !== VIEW.LEAD && (
                        <div className={VIEW_TYPE.LIST ? "col-md-8" : ""}>
                            <div className="view-type-control"
                                style={{
                                    marginRight: "10px",
                                    marginTop: "7px"
                                }}>
                                <OMSDropdown
                                    id="ViewTypeDD"
                                    options={viewTypeOptions}
                                    valueField="Value"
                                    labelField="Text"
                                    value={viewType}
                                    onChange={handleViewTypeChange}
                                />
                            </div>
                        </div>
                    )
                }

                {
                    view !== VIEW.LEAD && (
                        <div
                            className="tag-filter-control"
                            style={{
                                marginRight: "10px",
                                marginTop: "7px"
                            }}
                        >
                            <OMSMultiselectTagDropdown2
                                array1={officeTags}
                                array2={userTags}
                                array3={sourceTypesTag}
                                id="tag-source"
                                name="tag-source"
                                onChange={onChangeTags}
                                svalue={sources}
                                value={tags}
                            />
                        </div>
                    )
                }
                {
                    view === VIEW.LEAD && (
                        <div className="contact-lead-control"
                            style={{
                                marginRight: "10px",
                                marginTop: "7px",
                                width: "135px"
                            }}>
                            <OMSDropdown
                                id="mLeads"
                                name="mLeads"
                                options={usersDD}
                                valueField="value"
                                labelField="text"
                                value={mLeads}
                                onChange={onChangeLeads}
                            />

                        </div>
                    )
                }
                {
                    view === VIEW.LEAD && (
                        <div className="contact-lead-type-control"
                            style={{
                                marginRight: "10px",
                                marginTop: "7px",
                                width: "135px"
                            }}>
                            <select
                                id="mLeadType"
                                name="mLeadType"
                                style={{
                                    width: "auto",
                                }}
                                value={mLeadType}
                                onChange={onChangeLeadType}
                                className="form-control"
                            >
                                {leadTypeOptions.map((item, index) => (
                                    <option key={index} value={item.Value}>
                                        {item.Text}
                                    </option>
                                ))}

                            </select>



                        </div>
                    )

                }

                {

                    <div class="search-box-inline" style={{ marginTop: "7px" }}>
                        <input
                            type="text"
                            ref={refSearchBox}
                            value={searchTerm}
                            placeholder="Search..."
                            class="search-input-inline"
                            onChange={handleInputChange}
                        />


                        {
                            searchTerm.trim() && (
                                <span
                                    class="search-button-inline"
                                    onClick={() => onClickSeachReset("")}
                                >
                                    {/* <i class="fa fa-close"
                                            style={{
                                                color: "gray",
                                                fontSize: "16px",
                                                cursor: "pointer",
                                                //display: "none"
                                            }}></i> */}

                                    {LABEL.RESET_BTN_TEXT}
                                </span>
                            )}


                    </div>

                }
                {
                    view === VIEW.LEAD && (

                        <div className="form-group contact-lead-action-type-control">
                            <div className="lead-action-type-container">
                                <input
                                    className="cursor-pointer"
                                    type="radio"
                                    id="rbtnLeadActionTypeNew"
                                    name="rbtnLeadActionType"
                                    value={LEAD_ACTION_TYPE.NEW}
                                    checked={mLeadActionType === LEAD_ACTION_TYPE.NEW}
                                    onChange={onChangeLeadActionType}
                                />
                                <label htmlFor="rbtnLeadActionTypeNew" className="cursor-pointer">
                                    New
                                </label>
                            </div>

                            <div className="lead-action-type-container">
                                <input
                                    className="cursor-pointer"
                                    type="radio"
                                    id="rbtnLeadActionTypeConverted"
                                    name="rbtnLeadActionType"
                                    value={LEAD_ACTION_TYPE.CREATE}
                                    checked={mLeadActionType === LEAD_ACTION_TYPE.CREATE}
                                    onChange={onChangeLeadActionType}
                                />
                                <label htmlFor="rbtnLeadActionTypeConverted" className="cursor-pointer">
                                    Converted
                                </label>
                            </div>
                        </div>


                    )
                }

                <div className="add-new-button" style={{ marginTop: "7px" }}>
                    {actions.indexOf("create") != -1 && (
                        <OMSButtonStyleL
                            disabled={false}
                            onClick={() => history.push('/Contacts/SaveContact')}
                            processing={false}
                            type="button"
                            style={{
                                margin: "",
                            }}
                            ui_icon=""
                            ui_processing_text=""
                            ui_text="add contact"
                            ui_type="create"

                        />
                    )}
                </div>
            </div >
        </>








    );
}

export default ContactFilter;