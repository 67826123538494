import React from "react";
import { OMSMultiselectTagDropdown2 } from "../../app/common/common-components/OMSMultiselectTagDropdown";
import { OMSDropdown } from "../../app/common/common-components/OMSDropdown";
import { OMSButtonStyleL } from "../../app/common/common-components/OMSButton";
import { DataTableComp, DataTableCompDynamic } from "../../app/common/common-components/OMSDatatables";
import { OMSLeadActionModel } from "../../app/common/common-components/OMSInboxComponents/OMSLeadActionModel";
import { ReportGrid } from "./ReportGrid";
import apiAgent from "../../app/api/apiAgent";
import { RefFindNode } from "@fluentui/react-component-ref";
import { DataTableLoading } from "../../app/common/common-components/OMSDatatables";
import { withRouter } from 'react-router-dom';
import { VIEW_TYPE } from "./constants";

class ContactLeadGrid extends React.Component {

    debounce = (func, wait) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func(...args), wait);
        };
    };
    constructor(props) {
        super(props);
        this.reportGridRef = React.createRef();
        this.leadBoxRef = React.createRef();
    }

    loadGrid = (requestParams) => {
        const { view, mLeads, mLeadType, mLeadActionType, searchTerm } = this.props;

        //Url="/Inbox/ContactInboxAjax"
        //return apiAgent.Contact.getReferralContactsData("", this.state.tags, this.state.sources, this.state.referralstatus, this.state.view, this.state.contactViewType);
        return apiAgent.Contact.contactInboxAjax(
            mLeads,
            view,
            mLeadType,
            mLeadActionType,
            searchTerm
        );
    };
    componentDidUpdate(prevProps, prevState) {

        const {
            searchTerm = "",
            view = 1,
            mLeadType = 0,
            mLeads = 0,
            mLeadActionType = 0,
        } = this.props;

        if (
            prevProps.searchTerm !== searchTerm ||
            prevProps.view !== view ||
            prevProps.mLeadType !== mLeadType ||
            prevProps.mLeads !== mLeads ||
            prevProps.mLeadActionType !== mLeadActionType
        ) {
            this.debouncedLoadContacts();
        }


    }

    loadLeadsFromServer = (
        view,
        leads,
        leadtype,
        leadactiontype,
        search
    ) => {
        this.setState({
            hasTableShown2: true,
            view: view,
            mLeads: leads,
            mLeadType: leadtype,
            mLeadActionType: leadactiontype,
            mSearch: search,
            ExtraParams: JSON.stringify({
                View: view,
                Leads: leads,
                LeadType: leadtype,
                LeadActionType: leadactiontype,
                Search: search,

            }),
        });
    };


    RefreshLeadItems = () => {
        this.loadLeadsFromServer(
            this.state.view,
            this.state.mLeads,
            this.state.mLeadType,
            this.state.mLeadActionType,
            this.state.mSearch
        );
    };

    handleRowClick = (data) => {
        this.leadBoxRef.current.onInboxClick(data.userID, data.itemID, data.isRead, data.title);
    };

    debouncedLoadContacts = this.debounce(() => {
        const {
            searchTerm = "",
            view = 1,
            mLeadType = 0,
            mLeads = 0,
            mLeadActionType = 0
        } = this.props;
        this.loadLeadsFromServer(
            view,
            mLeads,
            mLeadType,
            mLeadActionType,
            searchTerm,

        );
    }, 1000);


    state = {
        loading: false,
        data2: [],
        view: 4,
        pageView: 1,
        tags: [],
        sources: [],
        mLeads: 0,
        mLeadType: 2,
        mLeadActionType: 0,
        mContactViewType: 0,
        mPipelineId: "",
        mSearch: "",
        ExtraParams: JSON.stringify({
            View: 1,
            Leads: 0,
            LeadType: 0,
            LeadActionType: 0,
            Search: "",
        }),

    };



    render() {
        const columns = [
            { data: "date", title: "Date" },
            { data: "referer", title: "Referer" },
            { data: "type", title: "Type" },
            { data: 'agent', title: 'Agent' },
            { data: "contactName", title: "Contact Name" },
            { data: "extPropertyRef", title: "Ext Property Ref" },
            { data: "propertyRef", title: "Property Ref" },
            { data: "actioned", title: "Actioned" },
        ];

        return (
            <div className="row">

                <div className="col-md-12 col-sm-12" style={{ display: this.state.pageView == 1 ? "" : "none" }}>
                    <div className="panel panel-default panel-table oms-box" data-intercom-target="grid-panel">

                        <div className="panel-body">
                            <div className="table-responsive" style={{ display: this.state.view == 4 ? "block" : "none" }}>
                                <DataTableCompDynamic
                                    id="referalTable"
                                    //Url="/Inbox/ContactInboxAjax"
                                    hasTableShown={this.state.hasTableShown2}
                                    columns={columns}
                                    columnDefs={[]}
                                    ordering={false}
                                    serverSide={false}
                                    searching={false}
                                    makeAjaxRequest={this.loadGrid}
                                    onRowClick={this.handleRowClick}
                                    ExtraParams={this.state.ExtraParams}
                                />
                                <DataTableLoading loading={this.state.loading} />
                            </div>
                            <div id="lead_container">
                                <OMSLeadActionModel
                                    IsReadOnly={(this.props.contact.actions.indexOf("create") < 0)}
                                    OfficeContactTags={this.props.contact.officeContactTags}
                                    UserContactTags={this.props.contact.userContactTags}
                                    ContactSourceTypes={this.props.contact.contactSourceTypes}
                                    DialingCode={this.props.contact.dialingCode}
                                    DialingCodeDD={this.props.contact.dialingCodeDD}
                                    ref={this.leadBoxRef}
                                    ID="myLeadBox"
                                    UserID=""
                                    View={1}
                                    RefreshFun={this.RefreshLeadItems}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.contact.super_admin ? <div className="col-md-11 col-sm-12" style={{ display: this.state.pageView == 100 ? "" : "none" }}></div> : null}
            </div>
        );
    }
}

export default withRouter(ContactLeadGrid);
